import { Injectable } from "@angular/core";
import { UserService } from "@smallstack/user-components";
import { currentProjectId$ } from "../../../app.subscriptions";
import { ResellerApiClient } from "../../api-client/services/api-client.factory";

@Injectable()
export class BackofficeUserService extends UserService {
  constructor(resellerApiClient: ResellerApiClient) {
    super(resellerApiClient);
  }

  public override async logout(): Promise<void> {
    currentProjectId$.next(undefined);
    await super.logout();
    await this.router.navigateByUrl("/login");
  }

  public async updateUserName(name: { firstName: string; lastName: string }): Promise<void> {
    await super.patchProfile(name);
  }
}
